import { gql } from '@apollo/client/core'

/**
 * Gets earning balances.
 */
export const GET_BALANCES = gql`
  query GetBalances ($input: GetBalancesInput!) {
     getBalances (input: $input) {
        balances {
          chainId
          balance
        }
      }
  }
`
