import { gql } from '@apollo/client/core'

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      displayName
      username
      icon
      banner
      website
      bio
    }
  }
`
