import { gql } from '@apollo/client/core'

export const RECORD_TIP = gql`
  mutation RecordTip($input: RecordTipInput!) {
    recordTip(input: $input) {
      hash
      amount
      value
      depositorId
      depositor {
        id
      }
      recipientId
      recipient {
        id
      }
      thanked
      created
    }
  }
`
