export default function usesBalance () {
  /**
   * A list of feature flags.
   *
   * Example:
   *
   * import { flags } from '#composbales/use-feature-flag
   * import { isFlagEnabled } from '#composables/use-feature-flag
   * isFlagEnabled('VITE_FEATURE_WALLETS')
   */
  const VITE_FEATURE_ARVIEWER = 'VITE_FEATURE_ARVIEWER'
  const VITE_FEATURE_CROSSMINT_LOGIN = 'VITE_FEATURE_CROSSMINT_LOGIN'
  const VITE_FEATURE_APPROVAL_PROCESS = 'VITE_FEATURE_APPROVAL_PROCESS'
  const VITE_FEATURE_REQUIREMENTS = 'VITE_FEATURE_REQUIREMENTS'

  const flags = {
    VITE_FEATURE_ARVIEWER,
    VITE_FEATURE_CROSSMINT_LOGIN,
    VITE_FEATURE_APPROVAL_PROCESS,
    VITE_FEATURE_REQUIREMENTS,
  }

  /**
   * Checks if a feature flag is enabled.
   */
  const isFlagEnabled = (flag: string) => {
    return !!(localStorage.getItem(flag) === 'true')
  }

  return {
    flags,
    isFlagEnabled,
  }

}
