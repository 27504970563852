<template>
  <div
    :id="`${parentProps?.id}-option-${index}`"
    ref="option"
    class="block relative py-2 px-4 pl-8 focus:bg-brand-border outline-none cursor-pointer hover:bg-brand-border/10 transition-colors select-none scroll-margin"
    :class="isSelected ? 'font-bold' : ''"
    role="option"
    :aria-selected="isSelected"
    tabindex="-1"
    @click="select"
  >
    <svg
      v-if="isSelected"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="w-4 h-4 absolute top-1/2 left-2 transform -translate-y-1/2 text-primary-800"
    ><polyline points="20 6 9 17 4 12" /></svg>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject, ref, onMounted, onUnmounted, computed, getCurrentInstance, watch } from 'vue'
import type { Ref } from 'vue'
import { SDropdownProps, SDropdownOptions, SOpen, SKeyboardClosed, SSelected, SFocused } from './symbols'


const parentProps = inject(SDropdownProps)
const parentOptions = inject(SDropdownOptions, ref([]))
const open = inject(SOpen, ref(false))
const selected = inject(SSelected, ref(0))
const focused = inject(SFocused, ref(0))
const keyboardClosed = inject(SKeyboardClosed, ref(false))

const index = ref(-1)
const option: Ref<HTMLElement | null> = ref(null)

const instance = getCurrentInstance()

const isSelected = computed(() => selected.value === index.value)
const isFocused = computed(() => focused.value === index.value)

onMounted(() => {
  parentOptions.value = [...parentOptions.value, { ref: instance?.refs.option as HTMLElement, uid: instance?.uid }]
  index.value = parentOptions.value.length - 1
})

onUnmounted(() => {
  parentOptions.value = parentOptions.value.filter(tab => tab.uid !== instance?.uid)
})

watch(
  isFocused,
  (isFocused) => {
    if (isFocused) {
      option.value?.focus()
    }
  }
)

const select = () => {
  keyboardClosed.value = false
  selected.value = index.value
  open.value = false
}
</script>

<style>
  .scroll-margin {
    scroll-snap-align: end;
    scroll-margin: 100%;
  }
</style>
