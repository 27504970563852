import { useSubscription, provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '#/client'

import { useMutation, useLazyQuery } from '#composables/use-apollo'

import { HAS_ACCESS } from '#queries/HasAccess'
import { GET_TOKEN_SUPPLY } from '#queries/GetTokenSupply'
import { GET_NEXT_DESIGN } from '#mutations/GetNextDesign'
import { RELEASE_TOKEN } from '#mutations/ReleaseToken'
import { RESERVE_TOKEN } from '#mutations/ReserveToken'
import { PURCHASE_COMPLETE } from '#mutations/PurchaseComplete'
import { CLAIM_TOKEN } from '#mutations/ClaimToken'
import { PURCHASE_TOKEN } from '#mutations/PurchaseToken'
import { TIP_ARTIST } from '#mutations/TipArtist'
import { ACCOUNT_ACTIVITY } from '#subscriptions/AccountActivity'

export const checkAccess = async (collection, account, tokenSupply) => {
  // check for collection status
  if (!['privatesale', 'presale', 'sale'].includes(collection.status)) {
    throw new Error('Collection not on sale')
  }

  // check for token gate
  await checkTokenGate(collection.id, collection.status)

  // check for token limit
  if (tokenSupply && tokenSupply.remaining < 1) {
    throw new Error('Token limit reached')
  }

  return true
}



const { load: queryGetTokenSupply } = useLazyQuery(GET_TOKEN_SUPPLY)

export const getTokenSupply = async (collectionId) => {
  const input = {
    collectionId,
  }

  const { data: { getTokenSupply: tokenSupply } } = await queryGetTokenSupply(input, { fetchPolicy: 'network-only' })

  return tokenSupply
}

const { load: queryHasAccess } = useLazyQuery(HAS_ACCESS)

export const checkTokenGate = async (collectionId, status) => {
  if (!collectionId) {
    return false
  }

  const input = {
    collectionId,
    status,
  }

  const { data: { hasAccess } } = await queryHasAccess(input, { fetchPolicy: 'network-only' })

  if (!hasAccess) {
    throw new Error('No access')
  }

  return hasAccess
}

const { load: mutateReleaseToken } = useMutation(RELEASE_TOKEN)

export const releaseToken = async (token) => {
  const input = {
    token,
  }

  const { data: { releaseToken } } = await mutateReleaseToken({ input })

  return releaseToken
}

const { load: mutateReserveToken } = useMutation(RESERVE_TOKEN)

export const reserveToken = async (collectionId, address, design) => {
  const input = {
    collectionId,
    address,
    design,
  }

  const { data: { reserveToken: tokenReservation } } = await mutateReserveToken({ input })

  return tokenReservation
}

const { load: mutateClaimToken } = useMutation(CLAIM_TOKEN)

export const claimToken = async (token) => {
  const input = {
    token,
  }

  const { data: { claimToken: tokenClaim } } = await mutateClaimToken({ input })
  if (!tokenClaim)  {
    throw new Error('Claim failed - unknown result')
  }
}

const { load: mutatePurchaseToken } = useMutation(PURCHASE_TOKEN)

export const purchaseToken = async (token, optionalPrice, payerAccountId) => {
  const input = {
    token,
  }
  
  if (optionalPrice) {
    input.optionalPrice = optionalPrice
  }
  
  if (payerAccountId) {
    input.payerAccountId = payerAccountId
  }
  
  //console.log('purchaseToken input', input)
  const { data: { purchaseToken: tokenPurchase } } = await mutatePurchaseToken({ input })

  return tokenPurchase
}

const { load: mutateTipArtist } = useMutation(TIP_ARTIST)

export const tipArtist = async (payerId, recipientId, amount, value, chainId) => {
  const input = {
    payerId, recipientId, amount, value, chainId,
  }
  
  console.log('tipArtist input', input)
  const { data: { tipArtist:tipArtistResult } } = await mutateTipArtist({ input })

  return tipArtistResult
}


const { load: mutatePurchaseComplete } = useMutation(PURCHASE_COMPLETE)

export const purchaseComplete = async (collectionId, tokenId, accountId, address, price, value, txHash) => {
  const input = {
    tokenId,
    collectionId,
    accountId,
    address,
    price,
    value,
    txHash,
  }

  const purchaseCompleteResult = await mutatePurchaseComplete({ input })

  return purchaseCompleteResult
}

const { load: mutateGetNextDesign } = useMutation(GET_NEXT_DESIGN)

export const getNextDesign = async (collection, nextDesignState) => {
  if (collection.type === 'Single') {
    return { designCode: '', state: null }
  } else if (collection.type === 'REMX') {
    const { data: { getNextDesign: { designCode, state } } } = await mutateGetNextDesign({ input: { collectionId: collection.id, state: nextDesignState } })

    return { designCode, state }
  } else {
    console.error('Unknown collection type', collection.type)

    return { designCode: '', state: null }
  }
}


export const startActivityMonitor = async (account, tokenId, send) => {
  try {
    const result = provideApolloClient(apolloClient)(
      () => {
        const {
          onResult,
          restart,
          stop,
        } = useSubscription(ACCOUNT_ACTIVITY, {
          id: account.id,
        })
        onResult((res) => {
          const data = res?.data?.accountActivity

          if (data?.tokenId === tokenId) {
            switch (data.__typename) {
              case 'TokenPurchaseConfirming':
                send({ type: 'CONFIRMING', data })

                break
              case 'TokenPurchaseFailed':
                send({ type: 'FAIL', data })

                break
              case 'TokenPurchaseSuccess':
                send({ type: 'SUCCESS', data })

                break
            }
          }
        })

        return {
          restart,
          stop,
        }
      }
    )

    return result

  } catch (error) {
    console.error('startActivityMonitor', error)
    throw error
  }
}
