<template>
  <button
    :id="`${id}-button`"
    class="w-full py-2 px-4 mr-4 overflow-hidden text-left focus:outline-none focus:ring-2 transition-all focus:ring-black focus:border-black "
    :class="$attrs.disabled && 'cursor-not-allowed'"
    aria-haspopup="true"
    :aria-disabled="$attrs.disabled ? true : false"
    :aria-controls="`${id}-list`"
    :aria-expanded="open"
  >
    <span class="overflow-hidden block">
      <slot />
    </span>

    <div
      class="absolute right-1 top-1/2 transform -translate-y-1/2"
      aria-hidden="true"
    >
      <Icon
        icon="ChevronDownIcon"
        class="w-5 h-5 text-black-500"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { SOpen } from './symbols'

import Icon from '#components/icon.vue'

defineProps({
  id: {
    type: String,
    required: true,
  },
})

const open = inject(SOpen, ref(false))
</script>
