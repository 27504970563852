<template>
  <div
    v-off="closePointer"
    class="relative w-full focus:ring-1 outline-none transition-all border-2 border-brand-border text-base lg:text-xl"
    :class="[disabled && 'bg-brand-bg-section text-brand-disabled-copy ', modelValue.label ? 'text-black' : 'text-brand-placeholder-light']"
    @keydown.up.prevent="prev"
    @keydown.down.prevent="next"
    @keydown.home.prevent="first"
    @keydown.end.prevent="last"
    @keydown.esc="closeKeyboard"
    @keydown.tab="handleTab"
    @keydown.space="select"
    @keydown.enter="select"
  >
    <DropdownButton
      v-if="modelValue.label"
      :id="id"
      ref="buttonElement"
      :disabled="disabled"
      @click.prevent="toggle"
    >
      {{ modelValue.label }}
    </DropdownButton>

    <DropdownButton
      v-else
      :id="id"
      ref="buttonElement"
      :disabled="disabled"
      @click.prevent="toggle"
    >
      <span
        v-if="srLabel"
        class="sr-only"
      >
        {{ srLabel }}
      </span>

      <span :aria-hidden="srLabel ? true : false">
        {{ label }}
      </span>
    </DropdownButton>

    <transition
      enter-active-class="transition motion-reduce:transition-none duration-150 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition motion-reduce:transition-none duration-150 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      @after-enter="focusElement"
      @after-leave="focusButton"
    >
      <DropdownOptions
        :id="`${id}-options`"
        ref="optionsElement"
        :aria-label="label"
        :aria-labelledby="`${id}-button`"
        :aria-activedescendant="activeDescendant"
      >
        <DropdownOption
          v-for="option, index in options"
          :key="index"
        >
          {{ option.label }}
        </DropdownOption>
      </DropdownOptions>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, provide } from 'vue'
import type { Ref, PropType } from 'vue'
import { SDropdownOptions, SDropdownProps, SFocused, SKeyboardClosed, SKeyboardOpened, SOpen, SSelected, type Option, type Children } from './symbols'

import DropdownButton from './dropdown-button.vue'
import DropdownOptions from './dropdown-options.vue'
import DropdownOption from './dropdown-option.vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object as PropType<Option>,
    required: true,
  },

  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  srLabel: {
    type: String,
    default: '',
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  position: {
    type: String as PropType<'left' | 'right'>,
    validator: (value: string) => ['left', 'right'].includes(value),
    default: 'left',
  },
})

const emit = defineEmits(['update:modelValue'])

const childrenOptions: Ref<Children[]> = ref([])
const buttonElement: Ref<InstanceType<typeof DropdownButton> | null> = ref(null)
const optionsElement: Ref<InstanceType<typeof DropdownOptions> | null> = ref(null)
const open = ref(false)
const selected = ref(-1)
const focused = ref(0)
const keyboardOpened = ref(false)
const keyboardClosed = ref(false)

provide(SDropdownProps, props)
provide(SDropdownOptions, childrenOptions)
provide(SOpen, open)
provide(SSelected, selected)
provide(SFocused, focused)
provide(SKeyboardOpened, keyboardOpened)
provide(SKeyboardClosed, keyboardClosed)

watch(
  selected,
  (selected) => {
    if (selected >= 0) {
      emit('update:modelValue', props.options[selected])
    }
  }
)

watch(
  [() => props.modelValue, () => props.options],
  (newVals) => {
    const [ newModelValue, newOptions ] = newVals
    // sets selected option immediately
    // if modelValue changed to an option that doesn't exist, `selected` will be set back to -1
    selected.value = newOptions.findIndex(option => option.value === newModelValue.value)
  },
  {
    immediate: true,
  }
)

const next = () => {
  if (open.value) {
    if (focused.value < childrenOptions.value.length - 1) {
      focused.value++
    }
  } else {
    open.value = true
  }
}

const prev = () => {
  if (open.value) {
    if (focused.value > 0) {
      focused.value--
    } else {
      focused.value = 0
    }
  } else {
    open.value = true
  }
}

const first = () => {
  focused.value = 0
}

const last = () => {
  focused.value = childrenOptions.value.length - 1
}

const close = () => {
  open.value = false
  focused.value = selected.value
  keyboardOpened.value = false
}

const closePointer = () => {
  keyboardClosed.value = false
  close()
}

const closeKeyboard = () => {
  keyboardClosed.value = true
  close()
}

const toggle = () => {
  open.value = !open.value
}

const handleTab = (e: Event) => {
  if (open.value) {
    e.preventDefault()
  }
}

const focusElement = () => {
  if (keyboardOpened.value) {
    childrenOptions.value[selected.value].ref.focus()
  }
}

const focusButton = () => {
  if (buttonElement.value && keyboardClosed.value) {
    buttonElement.value.$el.focus()
  }
}

const select = (evt: Event) => {
  if (open.value) {
    evt.preventDefault()
    selected.value = focused.value
    keyboardClosed.value = true
    close()
  } else {
    keyboardOpened.value = true
  }
}

const activeDescendant = computed(() => {
  return `${props.id}-option-${props.modelValue?.value}`
})
</script>
