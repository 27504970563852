<template>
  <div class="flex w-full flex-col">
    <GlobalButton
      v-if="showAddWalletButton"
      class="w-full"
      priority="primary-light"
      @click="navigateToProfile"
    >
      <span>
        Add wallet in profile
      </span>
    </GlobalButton>

    <Dropdown
      v-else
      id="digital-wallet"
      v-model:model-value="chosenWallet"
      :disabled="false"
      :options="dropDownWalletList || []"
      aria-label="Digital wallet"
      label="Digital wallet"
      @update:model-value="handleSetDefaultWallet"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { createPublicClient, http, formatEther } from 'viem'
import { base, baseSepolia } from 'viem/chains'

import { truncateMiddle } from '#utils/formatting'
  
import useAccount from '#composables/use-account'
import useLogin from '#composables/use-login'
import { useMutation } from '#composables/use-apollo'
  
import { UPDATE_PROFILE } from '#mutations/UpdateProfile'
import type { UpdateProfile, UpdateProfileVariables } from '#graphql/types/UpdateProfile'
  
import GlobalButton from '#components/global/global-button'
import Dropdown from '#components/global/dropdown/dropdown.vue'
  
const props = defineProps({
  allowRemxWallet: {
    type: Boolean,
    default: true,
  },
  showBalance: {
    type: Boolean,
    default: true,
  },
  chainId: {
    type: Number,
    default: 8453,
  },
})

const { account: useLoginAccount } = useLogin()
const { wallets, defaultWallet, id:accountId } = useAccount()
const router = useRouter()
  
const emit = defineEmits(['updated-wallet', 'navigating-to-profile', 'changing-wallet'])

const publicClient = createPublicClient({
  chain: props.chainId === 8453 ? base : baseSepolia,
  transport: http(),
})

const chosenWallet = ref({ label: '', value: useLoginAccount.value && useLoginAccount.value.defaultWallet })
const selectedWalletAddress = ref(useLoginAccount.value && useLoginAccount.value.defaultWallet)
const showAddWalletButton = computed(() => dropDownWalletList.value.length === 0)

const balances = {}
for (const wallet of wallets.value) {
  // wallet balance in eth to 5 decimal places
  balances[wallet.address] = parseFloat(formatEther(await publicClient.getBalance({ address: wallet.address }))).toFixed(5) 
}

const dropDownWalletList = computed(() => {
  if (!wallets.value) return []
  const filteredWallets = wallets.value.filter( (wallet) => {
    return (wallet.type !== 'remx') && wallet.connectionType?.toLowerCase() !== 'crossmint' 
  })

  return  filteredWallets.map(wallet => {
    return {
      label: `${wallet.label} (${truncateMiddle(wallet.address)}) ${props.showBalance ? `(${balances[wallet.address]} ETH)` : ''}` ,
      value: wallet.address,
    }
  })
})

const {
  mutate: updateProfile,
  loading: updateProfileLoading,
  onResult: updateProfileOnResult,
  onError: updateProfileOnError,
} = useMutation<UpdateProfile, UpdateProfileVariables>(UPDATE_PROFILE)
updateProfileOnResult((result) => {
 
  emit('updated-wallet', selectedWalletAddress.value)
})

updateProfileOnError((error) => {
  console.error('updateProfileOnError', error)
  emit('updated-wallet', selectedWalletAddress.value)
})


const handleSetDefaultWallet = async (address: any) => {
  const defaultAddress = address.value
  
  if (chosenWallet.value.value === defaultWallet.value) {
    return
  } 
  
  selectedWalletAddress.value = defaultAddress
  emit('changing-wallet')
  await updateProfile({ input: { id: accountId.value, defaultWallet: defaultAddress } })
  emit('updated-wallet', selectedWalletAddress.value)
}

const navigateToProfile = () => {
  emit('navigating-to-profile')
  router.push({ name: 'profile-edit', params: { profileSlug: useLoginAccount.value.slug }, hash: '#wallets' })
}
</script>
