import { gql } from '@apollo/client/core'

/**
 * Gets the next design from a collection.
 */
export const GET_NEXT_DESIGN = gql`
  mutation GetNextDesign($input: GetNextDesignInput!) {
    getNextDesign(input: $input)
    {
      designCode
      state
    }
  }
`
