import type { InjectionKey, Ref } from 'vue'

export interface Option {
  label: string;
  value: number | string;
}

export type Children = {
  ref: HTMLElement;
  uid?: number;
}

export const SOpen: InjectionKey<Ref<boolean>> = Symbol('SOpen')
export const SSelected: InjectionKey<Ref<number>> = Symbol('SSelected')
export const SFocused: InjectionKey<Ref<number>> = Symbol('SFocused')
export const SKeyboardOpened: InjectionKey<Ref<boolean>> = Symbol('SKeyboardOpened')
export const SKeyboardClosed: InjectionKey<Ref<boolean>> = Symbol('SKeyboardClosed')
export const SDropdownProps: InjectionKey<{ id: string; modelValue: Option, options: Option[], srLabel: string, disabled: boolean, position: 'left' | 'right' }> = Symbol('SDropdownProps')
export const SDropdownOptions: InjectionKey<Ref<Children[]>> = Symbol('SDropdownOptions')
