import { gql } from '@apollo/client/core'

export const PURCHASE_COMPLETE = gql`
  mutation PurchaseComplete($input: PurchaseCompleteInput!) {
    purchaseComplete(input: $input) {
      accountId
      collectionId
      id
      transactionHash
      status
    }
  }
`
