import { gql } from '@apollo/client/core'

/**
 * Gets the supply for a token.
 */
export const GET_TOKEN_SUPPLY = gql`
  query GetTokenSupply($collectionId: ID!) {
    getTokenSupply(collectionId: $collectionId) {
      owned
      used
      remaining
    }
  }
`
