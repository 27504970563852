<template>
  <div class="flex w-full">
    <div
      class="grow items-center pb-2 flex-col"
      tabindex="0"
    >
      <Title
        v-if="title && !singlePrice && !endComplete"
        class="w-full pb-0 lg:pb-4"
        black-text
      >
        {{ title }}
      </Title>
                  
      <div
        v-if="!singlePrice && !endComplete"
        class="flex flex-row pb-2 md:pb-4 w-full gap-1 md:gap-21"
      >
        <div
          v-for="amount in dollarAmounts"
          :key="amount"
          class="w-full"
        >
          <Body
            v-if="popular && amount === popular && !hidePopular"
            class="text-nowrap font-xs text-center h-5 mb-0 lg:mb-2 w-0 overflow-visible mx-auto -translate-x-12"
            size="sm"
            black-text
          >
            Most Popular 
          </Body>

          <div
            v-else
            class="h-5 mb-0 lg:mb-2"
          />

          <GlobalButton
            class="w-full active:outline-0 hover:outline-0 focus:outline-0"
            :class="supportAmount === amount? '':'focus:border-brand-border'"
            :size="isMobile? 'sm': 'base'"
            :priority="supportAmount === amount && !customActive? 'primary-dark': 'secondary-light'"
            @click="selectSupportAmount(amount)"
          >
            {{ amount === 0 ? 'Free': `$${amount}` }}
          </GlobalButton>
        </div>

        <div class="w-full">
          <div
            class="h-5 mb-0 lg:mb-2"
          />
          <GlobalButton
            class="w-full active:outline-0 hover:outline-0 focus:outline-0"
            :size="isMobile? 'sm': 'base'"
            :priority="customActive? 'primary-dark': 'secondary-light'"
            @click="handleCustomClick"
          >
            Custom
          </GlobalButton>
        </div>
      </div>

      <div
        v-if="customActive"
        class="flex flex-row w-full gap-1 md:gap-2 rounded-full border border-brand-border justify-between p-1 mb-2 md:mb-4"
        :class="isMobile? 'h-11': 'h-16'"
      >
        <GlobalButton
          class="w-12 md:w-16 active:outline-0 hover:outline-0 focus:outline-0 primary-dark text-start items-center justify-center h-full"
          :size="isMobile? 'sm': 'base'"
          :disabled="customValue <= props.customAmountIncrements"
          @click="handleMinusClick"
        >
          <div class="align-middle flex flex-col items-center justify-center content-center h-2">
            -{{ props.customAmountIncrements }}
          </div>
        </GlobalButton>

        <div class="flex flex-col justify-center pr-6">
          <div class="flex flex-row justify-center h-4 md:h-6">
            <Body
              class="w-16 text-nowrap font-medium text-right "
              :size="isMobile? 'sm': 'base'"
              black-text
            >
              $
            </Body>

            <Body
              class="w-16 text-nowrap font-medium text-center"
              :size="isMobile? 'sm': 'base'"
              black-text
            >
              <textarea
                id="customTextArea"
                ref="customTextArea"
                s
                data-test-id="textarea"
                class="truncate px-1.5 outline-none bg-transparent border-0"
                aria-multiline="false"
                maxlength="5"
                :value="customValue"
                v-bind="$attrs"
                aria-describedby="search-error"
                @input="validateCustomInput"
                @blur="handleBlurCustomInput"
              />
            </Body>
          </div>
        </div>

        <GlobalButton
          class="w-12 md:w-16 active:outline-0 hover:outline-0 focus:outline-0 primary-dark"
          :size="isMobile? 'sm': 'base'"
          :disabled="customValue > props.maxAmount - props.customAmountIncrements"
          @click="handlePlusClick"
        >
          <div class="align-middle flex flex-col items-center justify-center content-center h-2">
            +{{ props.customAmountIncrements }}
          </div>
        </GlobalButton>
      </div>

      <GlobalButton
        v-if="supportAmount === 0"
        class="w-full hover:translate-y-[-3px] active:translate-y-[0]"
        priority="primary-light"
        :size="isMobile? 'sm' : 'base'"
        :disabled="buttonDisabled"
       
        @click.stop="emit('claim', supportAmount)"
      >
        {{ getButtonTitle }}

        <LoadingIcon
          v-if="loading"
          class="animate-spin w-4 h-4"
        />
      </GlobalButton>

      <div
        v-else
        class="flex flex-row gap-2 w-full"
      >
        <GlobalButton
          class="w-full hover:translate-y-[-3px] active:translate-y-[0]"
          priority="primary-light"
          :size="isMobile? 'sm' : 'base'"
          :disabled="buttonDisabled"
          @click.stop="handleCreditCardClick"
        >
          Credit Card
        </GlobalButton>

        <GlobalButton
          class="w-full hover:translate-y-[-3px] active:translate-y-[0]"
          priority="primary-light"
          :size="isMobile? 'sm' : 'base'"
          :disabled="buttonDisabled"
       
          @click.stop="emit('claim-with-digital-wallet', supportAmount)"
        >
          {{ secondaryButtonText? secondaryButtonText : 'Digital Wallet' }}
        </GlobalButton>

        <GlobalButton
          v-if="!isDepositFlow"
          class="w-full hover:translate-y-[-3px] active:translate-y-[0]"
          priority="primary-light"
          :size="isMobile? 'sm' : 'base'"
          :disabled="buttonDisabled"
       
          @click.stop="emit('claim-with-balance', supportAmount)"
        >
          Balance
        </GlobalButton>
      </div>
    </div>
  </div>
</template>
  
<script setup lang="ts">
import { ref, PropType, computed, Ref } from 'vue'

import Body from '#components/typography/body'
import Title from '#components/typography/title'
import GlobalButton from '#components/global/global-button'
import LoadingIcon from '#components/icons/loading-icon.vue'
  
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String as PropType<string | null>,
    default: null,
    required: false,
  },
  description: {
    type: String as PropType<string | null>,
    default: null,
    required: true,
  },
  buttonTitle: {
    type: String,
    required: true,
  },
  buttonDisabled: {
    type: Boolean,
    required: true,
  },
  dollarAmounts: {
    type: Array as PropType<number[]>,
    default: () => ([]),
  },
  customAmount: {
    type: Number,
    default: 5,
  },
  maxAmount: {
    type: Number,
    default: 1500,
  },
  customAmountIncrements: {
    type: Number,
    default: 1,
  },
  popular: {
    type: Number as PropType<number | null>,
    default: null,
  },
  singlePrice:{
    type: Number as PropType<number | null>,
    default: null,
  },
  endComplete: {
    type: Boolean,
    default: false,
  },
  dualButtonFlow: {
    type: Boolean,
    default: false,
  },
  secondaryButtonText: {
    type: String as PropType<string | null>,
    default: null,
  },
  hidePopular: {
    type: Boolean,
    default: false,
  },
  isDepositFlow: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['claim','claim-with-credit-card','claim-with-digital-wallet','claim-with-balance'])

const supportAmount = ref(props.singlePrice || props.popular || 0)
const customActive = ref(false)
const customValue = ref(props.customAmount)
const customTextArea: Ref<HTMLTextAreaElement | null> = ref(null)

const isMobile = computed(() => window.innerWidth < 768)

const selectSupportAmount = (amount: number) => {
  const allowZero = props.dollarAmounts.includes(0)
  supportAmount.value =  supportAmount.value === amount ? allowZero?0:amount: amount

  if (props.dollarAmounts.includes(supportAmount.value))
  {
    customActive.value = false
  }
}

const handleCreditCardClick = () => {
  console.log('handleCreditCardClick supportAmount.value', supportAmount.value)
  emit('claim-with-credit-card', supportAmount.value)
}

const handleCustomClick = () => {
  customActive.value = true
  supportAmount.value = customValue.value
}

const handleMinusClick = () => {
  const newValue = customValue.value - props.customAmountIncrements
  customValue.value = newValue > 0 ? newValue : props.customAmountIncrements
  supportAmount.value = customValue.value

  if (customTextArea.value) {
    customTextArea.value.focus()
  }
}

const handlePlusClick = () => {
  let newValue = customValue.value + props.customAmountIncrements
  if (newValue > props.maxAmount)
  {
    newValue = props.maxAmount
  }
  customValue.value = newValue
  supportAmount.value = customValue.value

  if (customTextArea.value) {
    customTextArea.value.focus()
  }
}

const validateCustomInput = (event: Event) => {
  const input = event.target as HTMLTextAreaElement
  const value = input.value

  // Remove non-numeric characters
  const numericValue = value.replace(/\D/g, '')

  // Update the customValue with the numeric value
  customValue.value = Number(numericValue)

  // Update the input value with the numeric value
  input.value = numericValue

  if (numericValue === '0' || numericValue === '')  
  {
    supportAmount.value = props.customAmountIncrements
    return
  }
  else if (parseInt(value) > props.maxAmount)
  {
    customValue.value = props.maxAmount
    input.value = customValue.value.toString()
  }

  supportAmount.value = customValue.value
}

const handleBlurCustomInput = (evt: Event) => {
  const input = evt.target as HTMLTextAreaElement
  const value = input.value

  if (value.length === 0 || value === '0' || value === '')
  {
    customValue.value = props.customAmountIncrements
    supportAmount.value = customValue.value
    input.value = customValue.value.toString()
    return
  }
  else if (parseInt(value) > props.maxAmount)
  {
    customValue.value = props.maxAmount
    supportAmount.value = customValue.value
    input.value = customValue.value.toString()
    return
  }
}

const getButtonTitle = computed(() => {
  if (props.endComplete)
  {
    return 'Collection Closed'
  }

  return supportAmount.value === 0 ? 'Collect - Free': `${props.buttonTitle} - $${supportAmount.value} USD`
})
</script>
  
