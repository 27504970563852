<template>
  <div class="hidden md:flex w-full sticky top-24">
    <ul class="flex w-full flex-col gap-4 px-8">
      <li class="w-full">
        <SideNavItem
          icon="HomeIcon"
          text="Home"
          fill-icon-while-active
          :is-active="$route.name === 'home'"
          @click="navigateTo('home')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="DollarSignIcon"
          text="Earnings"
          :is-active="$route.name === 'earnings'"
          @click="navigateTo('me-earnings')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="PieChartIcon"
          text="Analytics"
          :is-active="$route.name === 'profile-analytics'"
          @click="navigateTo('me-analytics')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="BarChart2Icon"
          text="Leaderboard"
          :is-active="$route.name === 'leaderboard'"
          @click="navigateTo('leaderboard')"
        />
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <SideNavItem
          icon="UserIcon"
          text="Profile"
          :is-active="$route.name === 'profile-moments' && route.params.profileSlug === profileSlug"
          @click="navigateTo('profile-moments')"
        />
      </li>

      <li
        v-if="showInviteToEarn && hasOriginalReferralCode"
        class="w-full"
      >
        <SideNavItem
          icon="GiftIcon"
          text="Invite2Earn"
          :is-active="$route.name === 'invite2earn'"
          @click="navigateTo('invite2earn')"
        />
      </li>

      <li class="w-full flex flex-row content-start pt-4 z-50">
        <div class="w-full flex-col h-8">
          <button
            class="group flex flex-row content-start pl-2"
            @click="()=>profileMenuIsOpen = !profileMenuIsOpen"
          >
            <Icon
              icon="MoreHorizontalIcon"
              class="w-6 h-6 mr-2 lg:mr-4"
            />

            <Body
              class="pt-1 lg:pt-0"
              size="sm"
              black-text
            >
              More
            </Body>
          </button>

          <!-- Desktop Side menu -->
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div
              v-if="profileMenuIsOpen"
              class="hidden md:block absolute w-48 lg:w-60 border bg-white rounded-md md:translate-x-0 lg:translate-x-2 translate-y-4 drop-shadow-lg"
              @keyup.esc="closeProfileMenu"
            >
              <div class="flex flex-row w-full justify-end pr-3 pt-3">
                <button
                  class="w-6 h-6"
                  @click="closeProfileMenu"
                >
                  <Icon
                    class="text-brand-copy-light"
                    icon="XIcon"
                  />
                </button>
              </div>

              <div class="pb-6 px-3">
                <div class="flex flex-row w-full pb-2 px-3">
                  <Body size="xs">
                    SUPPORT
                  </Body>
                </div>

                <ul class="text-black space-y-1">
                  <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="handleCollect2EarnApplication"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        Drop Application
                      </Body>
                    </button>
                  </li>

                  <!-- <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="reportAnIssue"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        Report an issue
                      </Body>
                    </button>
                  </li> -->

                  <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="navigateToAndCloseMenu('contact')"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        Contact
                      </Body>
                    </button>
                  </li>

                  <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="navigateToAndCloseMenu('faq')"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        FAQ
                      </Body>
                    </button>
                  </li>

                  <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="navigateToAndCloseMenu('privacyPolicy')"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        Privacy Policy
                      </Body>
                    </button>
                  </li>

                  <li>
                    <button
                      class="w-full flex gap-x-2 rounded-md hover:bg-brand-bg-section cursor-pointer px-3"
                      @click="navigateToAndCloseMenu('termsOfService')"
                    >
                      <Body
                        black-text
                        size="sm"
                      >
                        Terms of Service
                      </Body>
                    </button>
                  </li>

                  <li>
                    <!-- Social Links -->
                    <ul class="mt-6 flex w-full md:items-center space-x-1 flex-row px-3">
                      <li>
                        <a
                          data-test-id="link-twitter"
                          href="https://twitter.com/remx_xyz"
                          target="_blank"
                          class="inline-block p-2 hover:bg-brand-bg-section rounded-lg -ml-2"
                        >
                          <XPlatformIcon
                            light-background
                            class="w-6 h-6 fill-black"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/remx_xyz/"
                          target="_blank"
                          class="inline-block p-2 hover:bg-brand-bg-section rounded-lg"
                        >
                          <InstagramIcon class="w-6 h-6" />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </li>

      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <GlobalButton
          priority="primary-dark"
          size="sm"
          class="mt-4"
          @click="handleOpenDepositModal"
        >
          Deposit Funds
          <div class="absolute w-10 h-4 lg:w-12 lg:h-6 bg-[#F82F68] rounded-full text-[9px] lg:text-[12px]  right-0 top-0 -translate-y-2 lg:-translate-y-3">
            New
          </div>
        </GlobalButton>
      </li>
      
      <li
        v-if="isAuthenticated"
        class="w-full"
      >
        <GlobalButton
          priority="primary-dark"
          size="sm"
          class="mt-0"
          @click="handleCreateMoment"
        >
          Create Moment
        </GlobalButton>
      </li>
    </ul>

    <CreateMomentUnauthorizedModal
      :is-open="isCreateMomentUnauthorizedModalOpen"
      @close="isCreateMomentUnauthorizedModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import useLogin from '#composables/use-login'
import useAccount from '#composables/use-account'

import GlobalButton from '#components/global/global-button'
import Icon from '#components/icon.vue'
import SideNavItem from '#components/home/side-nav-item.vue'
import Body from '#components/typography/body'
import CreateMomentUnauthorizedModal from '#components/global/create-moment-unauthorized-modal.vue'
import XPlatformIcon from '#components/icons/x-platform-icon.vue'

import { InstagramIcon } from '@creativelayer/chalk-icons'

const emit = defineEmits(['openDepositModal'])

const router = useRouter()
const route = useRoute()
const { isCreator, isAdmin } = useAccount()
const { account, isAuthenticated } = useLogin()

const urlQueryReferralCode = route.query.referralCode
const originalReferralCode = import.meta.env.VITE_VIRAL_LOOPS_REFERRAL_CODE

const isCreateMomentUnauthorizedModalOpen = ref(false)
const profileMenuIsOpen = ref(false)

const profileSlug = computed(() => account.value?.slug || '')
const showInviteToEarn = computed(() => account.value?.referralCode || account.value?.referrerCode || route.query.referralCode)
const hasOriginalReferralCode = computed(() => localStorage.getItem('referralCode') === originalReferralCode || urlQueryReferralCode === originalReferralCode || account.value?.referrerCode === originalReferralCode)

const closeProfileMenu = () => profileMenuIsOpen.value = false

const navigateTo = (path: string) => {
  if (path === 'invite2earn' && route.query.referralCode) {
    router.push({ name: 'invite2earn', query: { referralCode: route.query.referralCode } })
  } else if (path === 'invite2earn') {
    router.push({ name: 'invite2earn' })
  } else {
    router.push({ name: path, params: { profileSlug: profileSlug.value || '' } })
  }

  profileMenuIsOpen.value = false
}

const handleOpenDepositModal = () => {
  emit('openDepositModal')
}

const reportAnIssue = () => {
  const reportIssueUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdK5o7dZyskCFzsZfhyB8aLoJl_ekofAG7sSLbopP7WKUm51w/viewform`

  window.open(reportIssueUrl, '_blank')
  profileMenuIsOpen.value = false
}

const handleCollect2EarnApplication = () => {
  const collect2EarnApplicationUrl = `https://d674ncbpli8.typeform.com/to/DtsUCzH0`

  window.open(collect2EarnApplicationUrl, '_blank')
  profileMenuIsOpen.value = false
}

const navigateToAndCloseMenu = (path: string) => {
  navigateTo(path)
  closeProfileMenu()
}

const handleCreateMoment = () => {
  if (!isCreator.value && !isAdmin.value) {
    isCreateMomentUnauthorizedModalOpen.value = true
  } else {
    router.push({ name: 'moment-create', params: { profileSlug: account.value.slug || '' } })
  }
}
</script>
