import { gql } from '@apollo/client/core'

export const TIP_ARTIST = gql`
  mutation TipArtist($input: TipArtistInput!) {
    tipArtist(input: $input) {
      hash
      amount
      value
      depositorId
      depositor {
        id
      }
      recipientId
      recipient {
        id
        }
      thanked
      created
    }
  }
`
