import { gql } from '@apollo/client/core'

export const PURCHASE_TOKEN = gql`
  mutation PurchaseToken($input: PurchaseTokenInput!) {
    purchaseToken(input: $input) {
      contractId
      tokenId
      expiryBlock
      signature
      price
      result
    }
  }
`
