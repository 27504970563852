import { gql } from '@apollo/client/core'

/**
 * Checks if an account has access to gated collection.
 */
export const HAS_ACCESS = gql`
  query HasAccess($collectionId: ID!, $status: CollectionStatus) {
    hasAccess(collectionId: $collectionId, status: $status)
  }
`
